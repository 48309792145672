<template>
  <div class="h-[84%] overflow-y-auto scrollbar-hide">
    <Transition
      name="fade-fast"
      mode="out-in"
    >
      <component :is="component"></component>
    </Transition>
  </div>
</template>

<script setup>
import { useGetStartedStore } from "@/stores/getStarted";

const props = defineProps({
  standalone: Boolean,
});

const getStartedStore = useGetStartedStore();

const component = computed(() => {
  switch (getStartedStore.step) {
    case "signin":
      return resolveComponent("AuthSignIn");
    case "reset":
      return resolveComponent("AuthReset");
    case "createAccount":
      return resolveComponent("AuthCreateAccount");
    case "createPassword":
      return resolveComponent("AuthCreateAccountPassword");
    case "createDetails":
      return resolveComponent("AuthCreateAccountDetails");
    case "createLinkSent":
      return resolveComponent("AuthCreateAccountLink");
    case "createCodeSent":
      return resolveComponent("AuthCreateAccountOtp");
    case "resetPasswordValidateCode":
      return resolveComponent("AuthPasswordValidateCode");
    case "resetPasswordFill":
      return resolveComponent("AuthResetPasswordFill");
    default:
      return resolveComponent("AuthLanding");
  }
});
</script>

<style lang="scss" scoped>
.fade-fast-enter-active,
.fade-fast-leave-active {
  transition: opacity 180ms ease;
}

.fade-fast-leave-to,
.fade-fast-enter-from {
  opacity: 0;
}
</style>
