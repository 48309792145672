<template>
  <div>
    <FormContainer>
      <template #title>
        <span
          class="text-body-medium text-center text-white opacity-50 ltr:tracking-[0.72px]  rtl:tracking-normal"
        >
          {{ $t("Get Started") }}
        </span>
      </template>
      <template #subtitle>
        <h1
          class="text-display-small md:text-headline-big text-white opacity-[0.87] text-center ltr:tracking-[2.67px] rtl:tracking-normal"
        >
          {{ $t("Create an Account") }}
        </h1>
      </template>
      <form
        novalidate
        @submit.prevent="onSubmit"
        @invalid-submit.prevent="onInvalidSubmit"
        class="flex flex-col gap-y-6 justify-center"
      >
        <FormInput
          :label="t('Email')"
          :placeholder="t('Your email')"
          name="username"
        ></FormInput>

        <ButtonsCta
          :disabled="!form.meta.value.valid"
          :is-loading="isSubmitting"
          class="variant-primary"
        >
          {{ $t("Continue") }}
        </ButtonsCta>
      </form>
    </FormContainer>
  </div>
</template>

<script setup>
import { useGetStartedStore } from "@/stores/getStarted";

import { useForm } from "vee-validate";
import { localize } from "@vee-validate/i18n";

const props = defineProps({
  standalone: Boolean,
});

const { onListen } = useKeyBoardEvent("enter");
const { openToast } = useToast();

const getStartedStore = useGetStartedStore();

useFormRules();
const isSubmitting = ref(false);
const { t, locales, locale } = useI18n();

const schema = {
  username: "required|email",
};

const form = useForm({
  validationSchema: schema,
  initialValues: {
    username: getStartedStore.createForm.username,
  },
});

localize({
  en: {
    fields: {
      username: {
        required: "Please enter a valid email address",
        email: "Please enter a valid email address",
      },
    },
  },
});

const onInvalidSubmit = () => {};

const onSubmit = async (event) => {
  event?.preventDefault();
  isSubmitting.value = true;

  const { values } = form;

  // validate if username is already taken
  const { code, msg } = await useGetMethod(
    "/api/uc/manage/account/status",
    {
      username: values.username,
    },
    { raw: true }
  );
  if (code == "FAILURE") {
    openToast({ content: msg, type: "error" });
  } else {
    getStartedStore.createForm.username = values.username;
    getStartedStore.step = "createPassword";
  }
  isSubmitting.value = false;
};
onListen(() => form.meta.value.valid && onSubmit());
</script>

<script>
export default { name: "CreateAccount" };
</script>

<style lang="scss" scoped></style>
