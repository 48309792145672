<template>
  <div
    class="w-full flex flex-col justify-stretch items-center gap-2 md:gap-4 lg:gap-6 p-4 md:p-unset"
  >
    <div class="flex flex-col justify-center">
      <div class="flex flex-col items-center gap-y-2">
        <h1
          class="text-display-small md:text-display-medium text-white opacity-[0.87] text-center ltr:tracking-[2.67px] rtl:tracking-normal"
        >
          {{ $t("endless") }}
        </h1>
        <h2
          class="text-title-big md:text-headline-small text-center text-white opacity-[0.87] ltr:tracking-[0.72px] rtl:tracking-normal"
        >
          {{ $t("andMore") }}
        </h2>
      </div>
    </div>
    <div
      class="pt-8 pb-10 px-14 md:px-20 max-w-[32rem] flex flex-col justify-center items-center gap-y-2"
    >
      <div class="md:px-6 flex justify-between w-full">
        <img
          src="~/assets/pngs/adtv.png"
          alt="adtv"
          class="w-auto h-4 md:h-5 xl:h-5"
        />
        <img
          src="~/assets/pngs/adradio.png"
          alt="adradio"
          class="w-auto h-5 md:h-[25px] xl:h-[25px]"
        />
        <img
          src="~/assets/pngs/adsports.png"
          alt="adsports"
          class="w-auto h-4 md:h-5 xl:h-5"
        />
        <img
          src="~/assets/pngs/adarabic.png"
          alt="adarabic"
          class="w-auto h-4 md:h-5 xl:h-5"
        />
      </div>
      <span
        class="text-body-small text-white opacity-50 text-center rtl:tracking-normal"
      >
        {{ $t("existWith") }}
      </span>
    </div>
    <div class="w-full max-w-[24rem] flex flex-col gap-y-4">
      <ButtonsCta class="variant-primary" @click="onStep('createAccount')">
        {{ $t("Create an account") }}
      </ButtonsCta>
      <ButtonsCta
        class="variant-primary"
        @click="getStartedStore.step = 'signin'"
      >
        {{ $t("Sign In") }}
      </ButtonsCta>
      <div class="pt-10 flex justify-center">
        <button
          class="w-full text-body-medium text-white opacity-[0.87] text-center tracking-[0.42px]"
          @click="onGuest"
        >
          {{ $t("continueAsGuest") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useGetStartedStore } from "@/stores/getStarted";

const emit = defineEmits("on-guest");

const getStartedStore = useGetStartedStore();

const onStep = (step) => {
  getStartedStore.step = step;
  getStartedStore.modalBg = "gradient";
};

const onGuest = () => {
  getStartedStore.closeModal();
};
</script>

<style lang="scss" scoped></style>
