<template>
  <div>
    <FormContainer>
      <template #subtitle>
        <h1
          class="text-display-small md:text-headline-big text-white opacity-[0.87] text-center ltr:tracking-[2.67px] rtl:tracking-normal"
        >
          {{ $t("Set Your Password") }}
        </h1>
      </template>
      <form
        novalidate
        @submit.prevent="onSubmit"
        @invalid-submit.prevent="onInvalidSubmit"
        class="flex flex-col gap-y-6 justify-center"
      >
        <FormPassword
          :label="t('Password')"
          :placeholder="t('Your password')"
          :show-error-message="false"
          name="password"
        >
        </FormPassword>

        <!-- validator messages -->
        <div class="flex flex-col gap-y-2 text-body-small tracking-[0.36px]">
          <span class="text-white opacity-[0.50]">{{
            $t("The password should include a minimum of:")
          }}</span>

          <div class="flex flex-col gap-y-1">
            <FormPasswordValidationLabel
              :is-empty="!form.values.password"
              :is-valid="passwordRules.minLength.isValid"
            >
              <span
                :class="passwordRules.minLength.textColor"
                class="text-white opacity-[0.50]"
              >
                {{ $t("8 characters") }}
              </span>
            </FormPasswordValidationLabel>
            <FormPasswordValidationLabel
              :is-empty="!form.values.password"
              :is-valid="passwordRules.upperCase.isValid"
            >
              <span
                :class="passwordRules.upperCase.textColor"
                class="text-white opacity-[0.50]"
              >
                {{ $t("1 upper case alphabet") }}
              </span>
            </FormPasswordValidationLabel>

            <FormPasswordValidationLabel
              :is-empty="!form.values.password"
              :is-valid="passwordRules.lowerCase.isValid"
            >
              <span
                :class="passwordRules.lowerCase.textColor"
                class="text-white opacity-[0.50]"
              >
                {{ $t("1 lower case alphabet") }}
              </span>
            </FormPasswordValidationLabel>

            <FormPasswordValidationLabel
              :is-empty="!form.values.password"
              :is-valid="passwordRules.hasNumber.isValid"
            >
              <span
                :class="passwordRules.hasNumber.textColor"
                class="text-white opacity-[0.50]"
              >
                {{ $t("1 number") }}
              </span>
            </FormPasswordValidationLabel>

            <FormPasswordValidationLabel
              :is-empty="!form.values.password"
              :is-valid="passwordRules.hasSymbol.isValid"
            >
              <span
                :class="passwordRules.hasSymbol.textColor"
                class="text-white opacity-[0.50]"
              >
                {{ $t("1 symbol") }}
              </span>
            </FormPasswordValidationLabel>
          </div>
        </div>

        <FormPassword
          :label="t('Confirm password')"
          :placeholder="t('Your password')"
          name="confirmPassword"
        >
        </FormPassword>
        <ButtonsCta
          :is-loading="isSubmitting"
          :disabled="!form.meta.value.valid"
          class="variant-primary"
        >
          {{ $t("Continue") }}
        </ButtonsCta>
      </form>
    </FormContainer>
  </div>
</template>

<script setup>
import { useGetStartedStore } from "@/stores/getStarted";

import { useForm } from "vee-validate";
const { t } = useI18n();

const getStartedStore = useGetStartedStore();
const isSubmitting = ref(false);

useFormRules();

const schema = {
  password:
    "required|minLength:8|hasUppercase:1|hasLowercase:1|hasNumber:1|hasSymbol:1",
  confirmPassword: "required|equalTo:password",
};

const form = useForm({
  validationSchema: schema,
});
const {
  testMinLength,
  testUppercase,
  testLowercase,
  testHasNumber,
  testHasSymbol,
} = usePasswordValidation();

const passwordRules = computed(() => {
  const password = form.values.password;

  return {
    minLength: testMinLength(password),
    lowerCase: testLowercase(password),
    upperCase: testUppercase(password),
    hasNumber: testHasNumber(password),
    hasSymbol: testHasSymbol(password),
  };
});

const onInvalidSubmit = () => {};
const onResendCode = async () => {
  const res = await usePostMethod("/api/uc/manage/password/reset", {
    body: {
      username: getStartedStore.createForm.username,
      token: getStartedStore.ssoResponse.otpToken,
      new_password: form.values.password,
    },
  });
  const { code, status, msg } = res;
  if (code == "SUCCESS" && status == 200) {
    getStartedStore.$reset();
    getStartedStore.modalState = true;
    getStartedStore.step = "signin";
  } else {
    openToast({ content: msg, type: "error" });
  }
};
const onSubmit = () => {
  onResendCode();
};

watch(
  () => form.values,
  (newVal) => {
    const { password, confirmPassword } = newVal;
    password && form.setFieldTouched("password", true);
    confirmPassword && form.setFieldTouched("confirmPassword", true);
  },
  { deep: true }
);
</script>

<script>
export default { name: "PasswordValidation" };
</script>

<style lang="scss" scoped></style>
